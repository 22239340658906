import React from 'react'
import { useIntl } from "gatsby-plugin-react-intl"
import { Col, Container, Row } from "react-bootstrap"
import Slider from 'react-slick'
import Heading from '../heading'
import Media from '../media'
import HTMLReactParser from 'html-react-parser'
import { generateUrlByLocale } from '../functions'
import StyledButton from '../styledButton'
import Reveal from "react-awesome-reveal"
import { keyframes } from "@emotion/react"
import "./blockCoupDeCoeur.scss"

const BlockCoupDeCoeur = ({ content }) => {
    const intl = useIntl()
    const animLeftImg = keyframes`
  from {
    opacity: 0;
    clip-path: inset(0 100% 0 0);
  }
  to {
    opacity: 1;
    clip-path: inset(0);
  }
`
    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        draggable: true,
        lazyLoad: true,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "30px",
                    dots: false,
                },
            },
        ],
    }


    return (
        <section className="blk-coup-de-coeur">
            <Container>
                <Heading title={content.block_coup_de_coeur_title} />
                <Slider {...settings} className="custom-dots slickArrowBot">
                    {content.relationships.block_coup_de_coeur_items.map((item, index) => {
                        let praticalInfoLocation;

                        const componentsFiltered = item.relationships.field_components.filter(
                            value => Object.keys(value).length !== 0
                        )

                        componentsFiltered.map(function (component) {
                            const type = component.internal.type

                            switch (type) {
                                case "paragraph__practical_information":
                                    return praticalInfoLocation = component.relationships.location.name;
                                    break;
                                default:
                                    return praticalInfoLocation = "";
                                    break;
                            }
                        })

                        return (
                            <div className="slider-elem">
                                <Row key={index}>
                                    <Col md={5} className="image-col">
                                        {
                                            item.relationships.field_featured_media.relationships.media && (
                                                <Reveal
                                                    triggerOnce={true}
                                                    keyframes={animLeftImg}
                                                    cascade={true}
                                                >
                                                    <span className="bgdPlaceholder"></span>
                                                    <Media
                                                        media={
                                                            item.relationships.field_featured_media.relationships.media
                                                        }
                                                    />
                                                </Reveal>
                                            )
                                        }
                                    </Col>
                                    <Col md={7} className="info-col">
                                        <div className="info">
                                            {praticalInfoLocation && (
                                                <div className="caption">
                                                    <i className="icon-location-solid"></i>
                                                    {praticalInfoLocation}
                                                </div>
                                            )}
                                            <h3 className="font-style-2 ">{item.title}</h3>
                                            {item.relationships.field_featured_media.description && (
                                                <div className="description">
                                                    {HTMLReactParser(
                                                        item.relationships.field_featured_media.description
                                                            .processed
                                                    )}
                                                </div>
                                            )}
                                            <div className="cta">
                                                <StyledButton
                                                    title={intl.formatMessage({ id: "discover" })}
                                                    path={generateUrlByLocale(item.path.langcode, item.path.alias)}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })}
                </Slider>
            </Container>
        </section>
    )
}

export default BlockCoupDeCoeur
